import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, map, Observable, of } from 'rxjs';

import { ActorService, VeratrakAuthService } from '../services';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ActorGuard {
  constructor(
    private actor: ActorService,
    private router: Router,
    private veratrakAuthService: VeratrakAuthService
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.actor.getUserInfo().pipe(
      map(() => true),
      catchError((err: HttpErrorResponse) => {
        if (err.message === 'Unknown or invalid refresh token.') {
          this.veratrakAuthService.logout();
          return of(false);
        }

        void this.router.navigate(['/error'], { queryParams: { type: 'userinfo' } });

        throw new Error('User is not logged in');
      }),
    );
  }
}
