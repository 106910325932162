import { dataCollectionTemplateList } from '../constants';
import { DataCollectionTemplateCategory, DataCollectionTemplateId } from '../enums';
import { DataCollectionTemplate, SelectOption } from '../models';
import { getDateMonthsAgo, getFirstDayOfMonth } from '../utils';

/**
 * Get template list based on template category.
 *
 * @function
 * @param {DataCollectionTemplateCategory} templateCategory - template category.
 * @returns {SelectOption<DataCollectionTemplateId>[]} - list of templates.
 */
export const getTemplateList = (
  templateCategory: DataCollectionTemplateCategory,
): SelectOption<DataCollectionTemplateId>[] =>
  dataCollectionTemplateList
    .filter((template) => !!template.enabled)
    .map(({ category, id, shortDescription, name }) =>
      category === templateCategory ? { key: id, value: name, desc: shortDescription } : null,
    )
    .filter((template) => template) as SelectOption<DataCollectionTemplateId>[];

/**
 * Get Data Collection Template by template ID.
 *
 * @function
 * @param {string} templateId - template ID.
 * @returns {DataCollectionTemplate} - emission template.
 */
export const getDataCollectionTemplateById = (templateId: string): DataCollectionTemplate =>
  dataCollectionTemplateList.find(({ id }) => templateId === id) as DataCollectionTemplate;

/**
 * Get Data Collection Template name by template ID.
 *
 * @function
 * @param {string} templateId - template ID.
 * @returns {string} - emission template name.
 */
export const getDataCollectionTemplateName = (templateId: string): string => {
  const template = dataCollectionTemplateList.find((template) => template.id === templateId);

  return template?.name ? template.name : '';
};

/**
 * Get Warehouse Data Collection Templates by template IDs.
 *
 * @function
 * @param {string[]} templateIdList - template ID.
 * @returns {SelectOption<DataCollectionTemplateId>[]} - Warehouse Data Collection Templates List.
 */
export const getWarehouseDataTemplates = (templateIdList: string[]): SelectOption<DataCollectionTemplateId>[] => {
  return templateIdList
    .map((templateId) => {
      const template = dataCollectionTemplateList.find(
        (dataCollectionTemplate) => dataCollectionTemplate.id === templateId,
      );

      return (
        template && {
          key: template.id,
          value: template.name,
          desc: template.shortDescription,
          selected: false,
          category: template.category,
        }
      );
    })
    .filter(Boolean) as SelectOption<DataCollectionTemplateId>[];
};

/**
 * List of Warehouse Data Collection Templates.
 *
 * @constant
 * @type {SelectOption<DataCollectionTemplateId>[]}
 * @example [{key: 'WAREHOUSE_1', value: 'Warehouse 1', desc: 'Warehouse 1 option description'}]
 */
export const warehouseDataCollectionTemplateList: SelectOption<DataCollectionTemplateId>[] = getTemplateList(
  DataCollectionTemplateCategory.WAREHOUSE,
);

/**
 * List of Transport Warehouse Data Collection Templates.
 *
 * @constant
 * @type {SelectOption<DataCollectionTemplateId>[]}
 * @example [{key: 'TRANSPORT_1', value: 'Transport 1', desc: 'Transport 1 option description'}]
 */
export const transportDataCollectionTemplateList: SelectOption<DataCollectionTemplateId>[] = getTemplateList(
  DataCollectionTemplateCategory.TRANSPORT,
);

/**
 * Get the first day of the reporting month for emissions based on a specified number of months ago.
 *
 * @function
 * @param {number} monthAgo - The number of months ago to retrieve the reporting month.
 * @returns {string} - An ISO string representing the first day of the reporting month.
 * @example "2023-01-01T00:00:00.000Z"
 */
export const getEmissionsFirstDayOfReportingMonth = (monthAgo: number): string => {
  const dateMonthAgo = getDateMonthsAgo(monthAgo);
  const year = dateMonthAgo.getFullYear();
  const month = dateMonthAgo.getMonth();

  return getFirstDayOfMonth(year, month);
};

/**
 * Get the last day of the reporting month for emissions based on a specified number of months ago.
 *
 * @function
 * @param {number} monthAgo - The number of months ago to retrieve the reporting month.
 * @returns {string} - An ISO string representing the last day of the reporting month.
 * @example "2023-11-30T23:59:59.999Z"
 */
export const getEmissionsLastDayOfReportingMonth = (monthAgo: number): string => {
  const date = new Date(getEmissionsFirstDayOfReportingMonth(monthAgo));
  date.setMilliseconds(date.getMilliseconds() - 1);

  return date.toISOString();
};
